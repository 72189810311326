<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('change-office',3)">
         <ValidationObserver ref="form" >

             <app-row-left-label :label="trans('settings-office-name',207)" >
                 <app-input v-model="cArgs.office_name" mask="lettersE5-30" validatorId="name" validatorName="Naam kantoor" validatorRules="required"/>
             </app-row-left-label>

             <app-row-left-label :label="trans('blocked',3)" >
                 <app-check-box v-model="cArgs.status" />
             </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return", "filters"],
    
    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
        get() { return this.args },
        set() { this.$emit('update:args', false) }
        }
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("settings/offices/changeOffices", {
                            id_office_data_main: this.args.id_office_data_main,
                            office_name: this.args.office_name,
                            status: this.args.status,
                            filters: JSON.stringify(this.filters),
                        })
                       .then((res) => {
                            if(res.data == -1){
                                this.$refs.form.setErrors({name: this.trans('settings-name-already-taken',208)});
                                this.loading=false;
                                return false;
                            }
                            this.$emit("update:return", res.data.items);
                            this.$emit("update:show", false);
                            this.loading = false;
                        })
                        .catch((error) => {

                            console.error(`error during request: ${error}`);
                        });
                        }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>

<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-offices',207)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getLocations" :fields="fields" :filterOptions="user_status_list" :isFilterOptions="true" :isSearch="true" class="table-selectable">
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="editOffice(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                </template>

                <template v-slot:buttons>
                    <app-button type="primary" @click="mOfficeAdd.show = true">{{trans('settings-add-office',207)}}</app-button>
                </template>
            </app-view-table>
        </b-card>  
        <settings-offices-add v-if="mOfficeAdd.show" :show.sync="mOfficeAdd.show" :result.sync="mOfficeAdd.result" :filters.sync="filters" :return.sync="items"/>
        <settings-offices-edit v-if="mOfficeEdit.show" :show.sync="mOfficeEdit.show" :result.sync="mOfficeEdit.result" :args="mOfficeEdit.args" :filters.sync="filters" :return.sync="items"/>
    </div>
</template>

<script>
import axios from "@axios";
import SettingsOfficesAdd from "./SettingsOfficesAdd.vue";
import SettingsOfficesEdit from "./SettingsOfficesEdit.vue";

export default {
    components: { 
        SettingsOfficesAdd,
        SettingsOfficesEdit,
    },

     mounted() {
            this.onCreateThis();
    },
    
    data() {
        return {
            mOfficeAdd: { show: false, args: null, result: 0, return: null },
            mOfficeEdit: { show: false, args: { id_office_data_main: null, office_name: null, status: null }, result: 0, return: null },
            loading_list: false,
            user_status_list: [
                { value: 1, name: this.trans('active',3) },
                { value: 2, name: this.trans('blocked',3) },
            ],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "office_name", label: this.trans('full-name',3), thStyle: { width: "100%" }, visible: true},
                { key: "locations_count", label: this.trans('locations',3), thStyle: { "min-width": "160px", "max-width": "160px" }, visible: true},      
            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/offices/getOffices", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

       

        getLocations: function(items) {
            this.$router.push({ name: "locations", params: { id_office_data_main: items[0].id_office_data_main } });
        },

        editOffice: function(data) {
            this.mOfficeEdit.args.office_name = data.office_name;
            this.mOfficeEdit.args.id_office_data_main = data.id_office_data_main;
            this.mOfficeEdit.args.status = data.status;
            this.mOfficeEdit.show = true;
        },
        onCreateThis() { 

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
};
</script>

<style scoped></style>
